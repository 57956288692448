import styled from "styled-components"

export const ScheduleInitialConsultationContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})``

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 50px;
  text-align: center;
`
export const WidgetWrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`

  .calendly-inline-widget {
    min-height: 730px;
  }

`
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// Components
import Navbar from "components/Navbar/Navbar"
import HappyToStart from "components/HappyToStart/HappyToStart"
import NextSteps from "components/NextSteps/NextSteps"
import Footer from "components/Footer/Footer"
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper"
import ScheduleInitialConsultation from "components/ScheduleInitialConsultation/ScheduleInitialConsultation"

// Styles
import * as G from "styles/General.Styles"
// Redux
import * as CoachActions from "redux/coach/coach.actions"

const MyCoaching = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
    
    // const URL = "https://felix.foodiary.app/successful-booking-pro-r&narim0ken8".split('.app')[0] + ".app"
    const URL = window.location.href.split('.app')[0] + ".app"
    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: URL },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          )
        },
      })
    )
  }, [dispatch])

  return (
    <>
      <G.Container>
        <Navbar />
        <SectionWrapper margin="150px 0 0">
          <HappyToStart />
        </SectionWrapper>
        <SectionWrapper margin="150px 0 0px">
          <ScheduleInitialConsultation page='success' />
        </SectionWrapper>
        <SectionWrapper margin="100px 0">
          <NextSteps />
        </SectionWrapper>
      </G.Container>
      <Footer />
    </>
  )
}

export default MyCoaching

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { PopupButton } from "react-calendly"
import * as C from "./navbar.style"

import Button from "components/general/formComponents/button/Button"
// Images
import IconDownload from "assets/images/navbar/Icon-mobile.svg"
import IconLogin from "assets/images/navbar/Icon-Log-in.svg"
import IconMessage from "assets/images/navbar/Icon-Message.svg"
import IconClose from "assets/images/navbar/Icon-Close.svg"
import MenuIcon from "assets/images/Page4/menu.svg"
import Logo from "assets/images/Foodiary-Logo.png"

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [isSticky, setIsSticky] = useState(false)

  const coach = useSelector((state) => state.coach.coach)

  useEffect(() => {
    window.addEventListener("scroll", getSticky)
    return () => {
      window.removeEventListener("scroll", getSticky)
    }
  }, [])

  const getSticky = (e) => {
    const scrollTop = window.scrollY
    if (scrollTop >= 50) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }

  return (
    <>
      <C.Navbar className="navbar p-5 pl-8" isSticky={isSticky}>
        <C.Logo id="image-4-15" src={Logo} alt="logo" />
        <C.NavItems>
          <li>
            <NavLink className="menu-item"
              to="/mein-coaching"
              exact
              activeClassName="active-nav-link"
            >
              Mein Coaching
            </NavLink>
          </li>
          <li>
            <NavLink className="menu-item" to="/erstgespraech" exact activeClassName="active-nav-link">
              Erstgespräch
            </NavLink>
          </li>
          <li>
            <NavLink className="menu-item" to="/coaching-pro" exact activeClassName="active-nav-link">
              Coaching PRO
            </NavLink>
          </li>
          <li>
            <PopupButton
              className="ct-link-text foodiary-green-button cursor-pointer mt-12"
              styles={{ margin: "0 0 0 1rem" }}
              url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
              rootElement={document.getElementById("root")}
              text="Termin buchen"
            />
          </li>
        </C.NavItems>
        <C.MobileNavItems>
          <li>
            <PopupButton
              className="ct-link-text foodiary-green-button cursor-pointer mt-12"
              styles={{ margin: "0 1rem 0 0" }}
              url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
              rootElement={document.getElementById("root")}
              text="Termin buchen"
            />
          </li>
          <li>
            <img
              src={MenuIcon}
              alt="menu"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
          </li>
        </C.MobileNavItems>
      </C.Navbar>

      {showMobileMenu && (
        <C.MobileNavbar>
          <C.Header>
            <C.MobileNavbarLogo src={Logo} alt="logo" />
            <C.Close
              src={IconClose}
              alt="close"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
          </C.Header>
          <C.NavbarItemsWrapper>
            <C.MobileNavbarItems>
              <li>
                <NavLink
                  to="/mein-coaching"
                  exact
                  activeClassName="active-nav-link"
                >
                  Mein Coaching
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/erstgespraech"
                  exact
                  activeClassName="active-nav-link"
                >
                  Erstgespräch
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/coaching-pro"
                  exact
                  activeClassName="active-nav-link"
                >
                  Coaching PRO
                </NavLink>
              </li>
              <li>
                <C.Divider />
              </li>
              <li>
                <a href="https://www.foodiary.app/get-the-app/">
                  <C.AppDownloadItem>
                    <img src={IconDownload} alt="comment" /> App herunterladen
                  </C.AppDownloadItem>
                </a>
              </li>
              <li>
                <a href="https://login.foodiary.app/">
                  <C.LoginItem>
                    <img src={IconLogin} alt="comment" /> Login
                  </C.LoginItem>
                </a>
              </li>
            </C.MobileNavbarItems>
            <Button
              gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1))"
              margin="30px 0 15px 0"
              absolute={{ width: "auto", height: "auto" }}
              buttonStyles={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <img src={IconMessage} alt="comment" /> Erstgespräch vereinbaren
            </Button>
          </C.NavbarItemsWrapper>
        </C.MobileNavbar>
      )}
    </>
  )
}

export default Navbar
